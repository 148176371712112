import _toConsumableArray from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { CartRequestAction, CartItemType } from '@src/types/graphql-types';
import { cartRoute, CartType, CartView, CartFddLevel, EndUserCartType } from '@src/routes';
import messages from './messages';
// TODO: Is there a more complete list of these? Hardcoding them feels gross
export var PROMO_CODE_ERROR_MESSAGES = {
  '570': messages.PROMO_CODE_INVALID,
  '571': messages.PROMO_CODE_EXPIRED,
  '572': messages.PROMO_CODE_NOT_YET_VALID,
  '573': messages.PROMO_CODE_NOT_APPLIED
};
export var PROMO_CODE_ERROR_KEYS = Object.keys(PROMO_CODE_ERROR_MESSAGES);
export var hasCartItemPromoCodeError = function hasCartItemPromoCodeError(cartItem) {
  var _cartItem$errors$;

  return PROMO_CODE_ERROR_KEYS.includes(!!(cartItem !== null && cartItem !== void 0 && cartItem.errors) && (cartItem === null || cartItem === void 0 ? void 0 : (_cartItem$errors$ = cartItem.errors[0]) === null || _cartItem$errors$ === void 0 ? void 0 : _cartItem$errors$.errorNumber) || '');
};
export var hasSavedCartItemPromoCodeError = function hasSavedCartItemPromoCodeError(cartItem) {
  var _cartItem$itemSAPErro, _cartItem$itemSAPErro2;

  return PROMO_CODE_ERROR_KEYS.includes(((_cartItem$itemSAPErro = cartItem.itemSAPError) === null || _cartItem$itemSAPErro === void 0 ? void 0 : (_cartItem$itemSAPErro2 = _cartItem$itemSAPErro[0]) === null || _cartItem$itemSAPErro2 === void 0 ? void 0 : _cartItem$itemSAPErro2.errorNumber) || '');
};
export var hasCartItemMinimumOrderError = function hasCartItemMinimumOrderError(cartItem) {
  var min = cartItem.minimumOrderQuantity || 0;
  var quantity = cartItem.quantity;
  return quantity < min;
};
export var hasMultipleMiniMumOrderQuantityError = function hasMultipleMiniMumOrderQuantityError(quantity, minOrderQuantity, isIndonesiaUser) {
  var min = minOrderQuantity;

  if (!min || !quantity) {
    return false;
  }

  return isIndonesiaUser ? quantity < min : quantity % min !== 0;
};
export var hasCartItemMultipleMiniMumOrderError = function hasCartItemMultipleMiniMumOrderError(cartItem, isIndonesiaUser) {
  var min = cartItem.minimumOrderQuantity;
  var quantity = cartItem.quantity;

  if (!min || !quantity) {
    return false;
  }

  return isIndonesiaUser ? quantity < min : quantity % min !== 0;
};
export var checkMultipleMinimumQuantityError = function checkMultipleMinimumQuantityError(cartItems, setShowAlert, isIndonesiaUser) {
  var minQtyErrorsArray = cartItems.map(function (item) {
    return {
      minimumOrderQuantity: item.minimumOrderQuantity,
      materialNumber: item.materialNumber,
      quantity: item.quantity,
      error: hasCartItemMultipleMiniMumOrderError(item, isIndonesiaUser),
      id: item.id
    };
  });
  setShowAlert && setShowAlert(minQtyErrorsArray);
  return minQtyErrorsArray.some(function (item) {
    return item.error;
  });
};
export var materialIdIsPromotionalBundle = function materialIdIsPromotionalBundle(materialId) {
  return String(materialId).startsWith('BNDL');
};
export var getCartMiniAction = function getCartMiniAction() {
  var cartType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CartType.Active;

  switch (cartType) {
    case CartType.SavedCart:
      return CartRequestAction.Savedcartmini;

    case CartType.BlueSavedCart:
      return CartRequestAction.Bluesavedcart;

    case CartType.BuyNowCart:
      return CartRequestAction.Buyitnowcart;

    case CartType.MarketplaceBuyNowCart:
      return CartRequestAction.Marketplacebuyitnowcart;

    case CartType.ContractCart:
      return CartRequestAction.Contractcart;

    case CartType.DealerCart:
      return CartRequestAction.Dealercartmini;

    case CartType.MarketplaceCart:
      return CartRequestAction.Marketplace;

    case CartType.Orc:
      return CartRequestAction.Orcmini;

    case CartType.BlueCart:
    case CartType.Emerald:
    case CartType.Iscala:
      return CartRequestAction.Bluecartmini;

    case CartType.Quotefullyconvertcart:
      return CartRequestAction.Quotefullyconvertcartmini;

    default:
      return CartRequestAction.Mini;
  }
};
export var getMonths = function getMonths(placeHolder) {
  return [{
    value: '01',
    label: '01'
  }, {
    value: '02',
    label: '02'
  }, {
    value: '03',
    label: '03'
  }, {
    value: '04',
    label: '04'
  }, {
    value: '05',
    label: '05'
  }, {
    value: '06',
    label: '06'
  }, {
    value: '07',
    label: '07'
  }, {
    value: '08',
    label: '08'
  }, {
    value: '09',
    label: '09'
  }, {
    value: '10',
    label: '10'
  }, {
    value: '11',
    label: '11'
  }, {
    value: '12',
    label: '12'
  }, {
    value: '',
    label: placeHolder
  }];
};
export var getYears = function getYears(start, stop, step, placeHolder) {
  var years = Array.from({
    length: (stop - start) / step + 1
  }, function (_, i) {
    return start + i * step;
  });
  var yearsOptions = years.map(function (year) {
    return {
      value: year,
      label: year
    };
  });
  return [].concat(_toConsumableArray(yearsOptions), [{
    value: '',
    label: placeHolder
  }]);
};
export var getCreditCardType = function getCreditCardType(cardNumber) {
  if (cardNumber && /^3[0-9]{14}$/.test(cardNumber)) {
    return 'AX';
  } else if (cardNumber && /^4[0-9]{15}$/.test(cardNumber)) {
    return 'VI';
  } else if (cardNumber && /^5[0-9]{15}$/.test(cardNumber)) {
    return 'MC';
  }

  return null;
};
export var getCCExpireDate = function getCCExpireDate(expiryDate) {
  var _ref;

  var splitDate = (_ref = expiryDate !== null && expiryDate !== void 0 ? expiryDate : '/') === null || _ref === void 0 ? void 0 : _ref.split('/');
  return {
    month: splitDate === null || splitDate === void 0 ? void 0 : splitDate[0],
    year: splitDate === null || splitDate === void 0 ? void 0 : splitDate[1]
  };
}; // get cart type Param for fetchPartners api

export var getCartTypeParam = function getCartTypeParam(isBlueErpIntegrationEnabled) {
  var cartType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CartType.Active;

  if (isBlueErpIntegrationEnabled) {
    switch (cartType) {
      case CartType.Orc:
        return CartRequestAction.Orc;

      case CartType.BlueCart:
      case CartType.BlueSavedCart:
        return CartRequestAction.Bluecart;

      default:
        return null;
    }
  }

  return null;
};
export var getRedirctCartRoute = function getRedirctCartRoute(cartType) {
  var redirctCartType;

  switch (cartType) {
    case 'marketplace':
      redirctCartType = CartType.MarketplaceCart;
      break;

    case CartType.Emerald:
    case CartType.Iscala:
      redirctCartType = CartType.BlueCart;
      break;

    default:
      redirctCartType = CartType.Active;
      break;
  }

  return cartRoute[CartView.Cart](redirctCartType);
};
export var isRedCartFE = function isRedCartFE(cartType) {
  return cartType === CartType.Active || cartType === CartType.SavedCart;
};
export var displayItemPromoCode = function displayItemPromoCode(cartType) {
  return isRedCartFE(cartType);
};
export var displayHeaderPromoCode = function displayHeaderPromoCode(cartType, cart) {
  var isBlueCart = (cartType === CartType.BlueCart || cartType === CartType.BlueSavedCart) && (cart === null || cart === void 0 ? void 0 : cart.displayPromoCode);
  return isRedCartFE(cartType) || isBlueCart;
}; //check cart type based on BE cart response
//order type is the same as cart type, so we can use it to check if it's blue order too.

export var isBlueCartBE = function isBlueCartBE(cartType) {
  return cartType === CartType.Emerald || cartType === CartType.Iscala;
}; //check cart type based on type of FE URL

export var isBlueCartFE = function isBlueCartFE(cartType) {
  return cartType === CartType.BlueCart || cartType === CartType.BlueSavedCart;
};
export var isRedOrBlueFTBCart = function isRedOrBlueFTBCart(cartType) {
  return cartType === CartType.FTBCart || cartType === CartType.BlueFTBCart;
};
export var isStandingOrdersDisabled = function isStandingOrdersDisabled(item) {
  return !!item.promoCode || !!item.minimumOrderQuantity || item.quantity === 1;
};

var isUserFddEnabled = function isUserFddEnabled(currentUser) {
  var _currentUser$metadata;

  return (currentUser === null || currentUser === void 0 ? void 0 : currentUser.__typename) === 'LoggedInUser' && (currentUser === null || currentUser === void 0 ? void 0 : (_currentUser$metadata = currentUser.metadata) === null || _currentUser$metadata === void 0 ? void 0 : _currentUser$metadata.isFddEnabled) || false;
};

var getFddLevel = function getFddLevel(currentUser) {
  var _currentUser$metadata2;

  return (currentUser === null || currentUser === void 0 ? void 0 : currentUser.__typename) === 'LoggedInUser' && (currentUser === null || currentUser === void 0 ? void 0 : (_currentUser$metadata2 = currentUser.metadata) === null || _currentUser$metadata2 === void 0 ? void 0 : _currentUser$metadata2.fddLevel) || '';
};

export var isFddFieldEnabled = function isFddFieldEnabled(cartType, currentUser) {
  return isUserFddEnabled(currentUser) && cartType !== 'ftbcart' && cartType !== EndUserCartType.DealerEnduserCart && cartType !== EndUserCartType.DealerFtbCart;
};
export var getShouldRenderSelectShippingDate = function getShouldRenderSelectShippingDate(item) {
  var cartType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var currentUser = arguments.length > 2 ? arguments[2] : undefined;
  var fromQuote = arguments.length > 3 ? arguments[3] : undefined;
  return !fromQuote && isFddFieldEnabled(cartType, currentUser) && !(cartType === CartType.MarketplaceCart || cartType === CartType.MarketplaceBuyNowCart) && getFddLevel(currentUser) === CartFddLevel.Item && item.type === CartItemType.Prepack && cartType !== CartType.Quotefullyconvertcart;
};
export var getCheckoutItemElementId = function getCheckoutItemElementId(item) {
  return "checkout-item-".concat(item.id);
};